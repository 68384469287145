import { createGlobalStyle, css } from 'styled-components';

const Typography = createGlobalStyle`${css`
	* {
		box-sizing: border-box;
		outline: none;
		margin: 0;
		padding: 0;
		-webkit-appearance: none;
		-webkit-text-size-adjust: 100%;
		-webkit-font-smoothing: antialiased;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important; //! Touch highlight color
	}

	body {
		width: 100%;
		min-height: 100vh;
		font-family: var(--montserrat);
		background-color: var(--background);
		color: var(--black1000);
		overflow-x: hidden;
		position: relative;
		overflow: ${(props) => (props.popupIsOpen ? 'hidden' : '')};

		header,
		footer {
			filter: ${(props) => (props.popupIsOpen ? 'blur(1.5px)' : 'blur(0px)')};
		}

		main {
			position: relative;
			display: flex;
			flex-direction: column;
			min-height: 100vh;
			z-index: 1;
			transition: margin-top 1s;
			transition: filter var(--trTime);
			filter: ${(props) => (props.isBurgerMenuOpen || props.popupIsOpen ? 'blur(1.5px)' : 'blur(0px)')};
		}

		/* &::-webkit-scrollbar {
            display: none;
        } */

		&.hidden {
			overflow: hidden;
			max-height: 100vh;
		}
	}

	ul,
	fieldset {
		list-style: none;
		border: none;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		margin-block-start: 0;
		margin-block-end: 0;
	}

	a {
		color: unset;
		text-decoration: none;
	}

	button {
		border-width: 0;
		border-color: inherit;
		background-color: inherit;
	}

	textarea {
		resize: none;
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none; /* IE and Edge */

		&::-webkit-scrollbar {
			display: none;
		}
	}

	input[type='search']::-webkit-search-decoration,
	input[type='search']::-webkit-search-cancel-button,
	input[type='search']::-webkit-search-results-button,
	input[type='search']::-webkit-search-results-decoration {
		-webkit-appearance: none;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}

	input:focus::placeholder {
		color: transparent;
	}

	input {
		outline: none;
	}

	//! Change the transparent to any color
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	textarea:-webkit-autofill:active,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus,
	select:-webkit-autofill:active,
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active,
	input:-internal-autofill-selected {
		transition: background-color 5000s ease-in-out 0s;
		box-shadow: inset 1000px 1000px var(--white);
		-webkit-text-fill-color: var(--black1000);
	}
`}`;

export default Typography;
