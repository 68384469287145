import { createGlobalStyle, css } from 'styled-components';

function columnGenerator(suffix) {
	let style = '';
	for (let i = 1; i <= 12; i++) {
		style += suffix ? `.col-${suffix}-${i}` : `.col-${i}`;
		style += `{ width: ${(100 / 12) * i}% } `;
	}

	return style;
}

const HelperClass = createGlobalStyle`${css`
	.background {
		color: var(--background);

		&-bg {
			background-color: var(--background);
		}
	}

	.opacity {
		&-0 {
			opacity: 0;
		}
	}

	.translate-y {
		&-100 {
			transform: translateY(100%);
		}
	}

	.text-center {
		text-align: center;
	}

	.overflow-hidden {
		overflow: hidden;
	}

	.white {
		color: var(--white);

		&-bg {
			background-color: var(--white);
		}
	}

	.main-green {
		color: var(--green800);

		&-bg {
			background-color: var(--green800);
		}
	}

	.dark-green {
		color: var(--green1000);

		&-bg {
			background-color: var(--green1000);
		}
	}

	.error-color {
		color: var(--errorColor);

		&-bg {
			background-color: var(--errorColor);
		}
	}

	.red {
		color: var(--red900);

		&-bg {
			background-color: var(--red900);
		}
	}

	.main-red {
		color: var(--red1000);

		&-bg {
			background-color: var(--red1000);
		}
	}

	.black1000 {
		color: var(--black1000);
	}

	//! Font sizes
	h1,
	.h1 {
		font-size: var(--h1);
		line-height: var(--lineHeight3);
	}

	h2,
	.h2 {
		font-size: var(--h2);
		line-height: var(--lineHeight3);
	}

	h3,
	.h3 {
		font-size: var(--h3);
		line-height: var(--lineHeight3);
	}

	h4,
	.h4 {
		font-size: var(--h4);
		line-height: var(--lineHeight2);
	}

	h5,
	.h5 {
		font-size: var(--h5);
		line-height: var(--lineHeight2);
	}

	h6,
	.h6 {
		font-size: var(--h6);
		line-height: var(--lineHeight3);
	}

	p,
	.p {
		font-size: var(--p1);
		line-height: var(--lineHeight1);

		&-2 {
			font-size: var(--p2);
			line-height: var(--lineHeight1);
		}
	}

	//! Font families
	.font-montserrat {
		font-family: var(--montserrat);
		font-weight: 400 !important;

		&-medium {
			font-family: var(--montserrat);
			font-weight: 500 !important;
		}

		&-semibold {
			font-family: var(--montserrat);
			font-weight: 600 !important;
		}
	}

	.compas-icon {
		font-size: var(--compasIconSizes);
	}

	.arrow-long-icon {
		font-size: var(--arrowLongIconSizes);
	}

	.location-icon {
		font-size: var(--locationIconSizes);
	}

	.cross-icon {
		font-size: var(--crossIconSizes);
	}

	.uppercase {
		text-transform: uppercase;
	}

	//! Row
	.row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 calc(var(--colPadding) * -1);

		> * {
			width: 100%;
			padding: 0 var(--colPadding);
		}

		${columnGenerator()}

		//! 1920
        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXLMin}) {
			${columnGenerator('dl')}
		}

		//! 1510
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeLMin}) {
			${columnGenerator('dm')}
		}

		//! 1440
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeMMin}) {
			${columnGenerator('ds')}
		}

		//! 1280
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeSMin}) {
			${columnGenerator('dxs')}
		}

		//! 1024
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
			${columnGenerator('t')}
		}

		//! 768
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
			${columnGenerator('ts')}
		}

		//! 414
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
			${columnGenerator('m')}
		}
	}

	@keyframes loading {
		0%,
		100% {
			opacity: 0.7;
		}

		50% {
			opacity: 0.5;
		}
	}
`}`;

export default HelperClass;
