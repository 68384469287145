'use client';
import { useState, useMemo, createContext } from 'react';

//* Helpers
import { api } from '@/helpers';

const DataContext = createContext(null);
export const DataConsumer = DataContext.Consumer;

const DataProvider = (props) => {
    const [state, setState] = useState({
        ...props.newData,
        prevRoute: null,
    });

    const enableLoading = () => {
        this.setState({ loading: true });
    };

    const disableLoading = () => {
        setState((prev) => ({ ...prev, loading: false }));
    };

    const contactUs = async (data) => {
        return await api.post('contact-us', data);
    };

    const globalData = useMemo(() => state.global?.[props.selectedLang], [props.selectedLang, state])

    const methods = {
        contactUs,
        enableLoading,
        disableLoading,
    };

    return (
        <DataContext.Provider
            value={{
                ...state,
                ...methods,
                globalData
            }}>
            {props.children}
        </DataContext.Provider>
    );
};

export default DataProvider;
