export default {
	colors: {
		//! Main
		disable: '#DDDDDD',
		background: '#F2F0EF',
		errorColor: '#DE1010',
		white: '#ffffff',
		blackLayer: '#000000',

		//! Black versions
		black1000: '#1D1E1C',
		black900: '#333432',
		black800: '#4A4B49',
		black700: '#60615F',
		black600: '#777876',
		black500: '#8D8E8D',
		black400: '#A4A5A4',
		black300: '#BABBBA',
		black200: '#D1D2D1',
		black150: '#DDDDDD',
		black100: '#E8E8E7',
		black50: '#F3F3F3',

		//! Red versions
		red1000: '#E45F53',
		red900: '#E66E63',
		red800: '#E97F75',
		red700: '#EC8E86',
		red600: '#EF9F97',
		red500: '#F1AEA8',
		red400: '#F4BFBA',
		red300: '#F7CECB',
		red200: '#FADFDC',
		red100: '#FDEFED',
		red50: '#FEF7F6',

		//! Green versions
		green1000: '#8CA69E',
		green800: '#B0C9C0',
		green600: '#D4E1DD',
		green400: '#E2EBE8',
		green200: '#F1F5F4',
		green100: '#F8FAF9',
	},

	mediaQuery: {
		DesktopSizeXL: '2560px',
		DesktopSizeL: '1920px',
		DesktopSizeM: '1510px',
		DesktopSizeS: '1440px',
		DesktopSizeXS: '1280px',
		TabletSize: '1024px',
		TabletSizeS: '768px',
		MobileSize: '601px',
		MobileSizeS: '398px',

		DesktopSizeXLMin: '2559px',
		DesktopSizeLMin: '1919px',
		DesktopSizeMMin: '1509px',
		DesktopSizeSMin: '1439px',
		DesktopSizeXSMin: '1279px',
		TabletSizeMin: '1023px',
		TabletSizeSMin: '767px',
		MobileSizeMin: '600px',
	},
};
